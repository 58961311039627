import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import AlbumSelection from "./pages/Album-Selection/AlbumSelection";
import AlbumView from "./components/AlbumView/AlbumView";
import PortfolioAlbumView from './components/PortfolioAlbumView/PortfolioAlbumView';
import PortfolioVideoView from './components/PortfolioVideoView/PortfolioVideoView';
import CompUpload from "./pages/CompUpload/CompUpload";
import EGQuotation from './pages/EGQuotation/Quotation';
import WGQuotation from './pages/WGQuotation/Quotation';
import EGNonWeddingQuotation from './pages/EGNonWeddingQuotation/NonWeddingQuotation'
import VideoView from "./components/VideoView/VideoView";
import WGNonWeddingQuotation from './pages/WGNonWeddingQuotation/NonWeddingQuotation'
import { Platform } from "./script/util"

function App() {
  React.useEffect(() => {
    if (Platform.isWG) {
      document.title = 'WedGraphia'
      var favicon_links = `
      <link rel="apple-touch-icon" sizes="57x57"
          href="https://assets.graphia.in/site_media/wedgraphia/logo/black57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60"
          href="https://assets.graphia.in/site_media/wedgraphia/logo/black60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72"
          href="https://assets.graphia.in/site_media/wedgraphia/logo/black72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76"
          href="https://assets.graphia.in/site_media/wedgraphia/logo/black76x76.png" />
      <link rel="apple-touch-icon" sizes="114x114"
          href="https://assets.graphia.in/site_media/wedgraphia/logo/black114x114.png" />
      <link rel="apple-touch-icon" sizes="120x120"
          href="https://assets.graphia.in/site_media/wedgraphia/logo/black120x120.png" />
      <link rel="apple-touch-icon" sizes="144x144"
          href="https://assets.graphia.in/site_media/wedgraphia/logo/black144x144.png" />
      <link rel="apple-touch-icon" sizes="152x152"
          href="https://assets.graphia.in/site_media/wedgraphia/logo/black152x152.png" />
      <link rel="apple-touch-icon" sizes="180x180"
          href="https://assets.graphia.in/site_media/wedgraphia/logo/black180x180.png" />
      <link rel="icon" type="image/png" sizes="192x192"
          href="https://assets.graphia.in/site_media/wedgraphia/logo/black192x192.png" />
      <link rel="icon" type="image/png" sizes="32x32"
          href="https://assets.graphia.in/site_media/wedgraphia/logo/black32x32.png" />
      <link rel="icon" type="image/png" sizes="96x96"
          href="https://assets.graphia.in/site_media/wedgraphia/logo/black96x96.png" />
      <link rel="icon" type="image/png" sizes="16x16"
          href="https://assets.graphia.in/site_media/wedgraphia/logo/black16x16.png" />`
      document.head.insertAdjacentHTML('beforeend', favicon_links);
    } else {
      document.title = 'EventGraphia'
      var favicon_links = `
      <link rel="apple-touch-icon" sizes="57x57"
        href="https://res.cloudinary.com/eventgraphia/image/upload/v1492249970/webapp/favicon/apple-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60"
        href="https://res.cloudinary.com/eventgraphia/image/upload/v1492249970/webapp/favicon/apple-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72"
        href="https://res.cloudinary.com/eventgraphia/image/upload/v1492249971/webapp/favicon/apple-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76"
        href="https://res.cloudinary.com/eventgraphia/image/upload/v1492249970/webapp/favicon/apple-icon-76x76.png" />
      <link rel="apple-touch-icon" sizes="114x114"
        href="https://res.cloudinary.com/eventgraphia/image/upload/v1492249970/webapp/favicon/apple-icon-114x114.png" />
      <link rel="apple-touch-icon" sizes="120x120"
        href="https://res.cloudinary.com/eventgraphia/image/upload/v1492249970/webapp/favicon/apple-icon-120x120.png" />
      <link rel="apple-touch-icon" sizes="144x144"
        href="https://res.cloudinary.com/eventgraphia/image/upload/v1492249971/webapp/favicon/apple-icon-144x144.png" />
      <link rel="apple-touch-icon" sizes="152x152"
        href="https://res.cloudinary.com/eventgraphia/image/upload/v1492249971/webapp/favicon/apple-icon-152x152.png" />
      <link rel="apple-touch-icon" sizes="180x180"
        href="https://res.cloudinary.com/eventgraphia/image/upload/v1492249971/webapp/favicon/apple-icon-180x180.png" />
      <link rel="icon" type="image/png" sizes="192x192"
        href="https://res.cloudinary.com/eventgraphia/image/upload/v1492249972/webapp/favicon/android-icon-192x192.png" />
      <link rel="icon" type="image/png" sizes="32x32"
        href="https://res.cloudinary.com/eventgraphia/image/upload/v1492249971/webapp/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="96x96"
        href="https://res.cloudinary.com/eventgraphia/image/upload/v1492249971/webapp/favicon/favicon-96x96.png" />
      <link rel="icon" type="image/png" sizes="16x16"
        href="https://res.cloudinary.com/eventgraphia/image/upload/v1492249971/webapp/favicon/favicon-16x16.png" />`
      document.head.insertAdjacentHTML('beforeend', favicon_links)
    }
  }, [])
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/:id?" element={<Dashboard />}></Route>
          <Route path="/album-selection" element={<AlbumSelection />}></Route>
          <Route path="/album" element={<AlbumView />}></Route>
          <Route  path='/portfolio-video' element={<PortfolioVideoView />}></Route>
          <Route path='/portfolio-album' element={<PortfolioAlbumView />}></Route>
          <Route path="/compupload" element={<CompUpload />}></Route>
          <Route path='/video' element={<VideoView/>}></Route>
          {Platform.isWG ? (
            <>
              <Route path='/quotation' element={<WGQuotation />}></Route>
              <Route path='/proposal' element={<WGQuotation />}></Route>
              <Route path='/quotation-preview' element={<WGQuotation preview={true} />}></Route>
              <Route path='/quote' element={<WGNonWeddingQuotation />}></Route>
              <Route path='/quote-preview' element={<WGNonWeddingQuotation preview={true} />}></Route>
            </>
          ) : (
            <>
              <Route path='/quotation' element={<EGQuotation />}></Route>
              <Route path='/proposal' element={<EGQuotation />}></Route>
              <Route path='/quotation-preview' element={<EGQuotation preview={true} />}></Route>
              <Route path='/quote' element={<EGNonWeddingQuotation />}></Route>
              <Route path='/quote-preview' element={<EGNonWeddingQuotation preview={true} />}></Route>
            </>
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
