import { getAuthToken, isAdmin } from "./util.js";
import axios from "axios";

export function QuotData(preview, id) {
    var token, headers = {}, url_para = '';
    
    if (localStorage.getItem("quot-key") && !preview) {
        token = localStorage.getItem("quot-key");
        url_para = '?quot-key=' + token;
    } else {
        headers = {
            Authorization: getAuthToken(),
        };
    }
    
    if (isAdmin() && !preview) {
        return Promise.resolve();
    }
    
    if (!isAdmin() && preview) {
        return Promise.resolve();
    }
    
    var api_url = `${process.env.REACT_APP_STAG_URL}/api/estimation/estimation/${id}/${url_para}`;
    if (preview) {
        api_url = `${process.env.REACT_APP_STAG_URL}/api/estimation/estimation/${id}/preview/`;
    }
    
    return axios({
        url: api_url,
        method: "get",
        headers: headers,
    }).then((response) => {
        return response.data;
    });
}
